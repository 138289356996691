import React from "react";
import { withPrefix } from "gatsby";

const Hero = () => {
  return (
    <div className="pt-4 pt-md-6 pt-lg-10">
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-11">
            <div
              className="text-center mb-11 mb-lg-10"
              data-aos="fade-up"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h1 className="font-size-11 mb-5">The Digma Interactive Player Wordpress Plugin</h1>
              <h2 className="font-size-7 mb-5">
                Download your plugin here!
              </h2>
              <a className="btn btn btn-dodger-blue-2 btn-medium rounded-5 font-size-3 mt-5 mb-10" href={withPrefix('/wordpressplugin/digma-interactive-video.zip')}>Download Plugin</a>
              <p>Once installed and activated in your wordpress CMS this will insert the JavaScript and CSS for our Interactive Video Player.</p>
              <p>You can then insert the Digma Video Snippet created in the Digma CMS into your website manually or use the short code below.</p>
              <p>[digma_video id="YOUR-DIGMA-PROJECT-ID"]</p>
            </div>
          </div>
        </div>
        {/* End Section Padding */}
      </div>
    </div>
  );
};

export default Hero;
