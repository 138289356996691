import React from "react";
import {Link} from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/wordpress/Hero";
import Commit from "../sections/common/Commit";


import SEO from '../components/SEO';
import metaImg from '../assets/image/metaimg/default.jpg'

const Wordpress = () => {
  return (  
    <>
   
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Digma Interactive Video Player Wordpress Plugin"
          description="The Wordpress plugin for the Digma Interactive Video Player"
          image={metaImg}
        />             
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/wordpressplugin">Wordpress Plugin</Link>
              </li>
              </ol>
           </nav>
        </div>  
        <Hero />
        <div style={{overflow:"hidden", background:"#f7f9fc"}}>
          <Commit />
        </div>   

      </PageWrapper>
    </>
  );
};
export default Wordpress;
